import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  activate as activateAction,
  deactivate as deactivateAction,
  setHeadline as setHeadlineAction,
  setShortcutVisible as setShortcutsVisibleAction,
  setTheme as setThemeAction,
  setDuration as setDurationAction,
} from 'redux/modules/navbar';

import BTE from 'lib/BTE';

export const NAVBAR_THEME = {
  TRANSPARENT: 'transparent',
  TRANSPARENT_LIGHT: 'transparentLight',
  LIGHT: 'light',
  DARK: 'dark',
  VERTICAL: 'vertical',
};


/**
 * A higher-order component that wraps a Page component with the Navbar component.
 * The Navbar component has props that are connected to the Redux store,
 * allowing it to access the navbar state and dispatch actions to update it.
 *
 * @param {object} configuration - Navbar configuration options.
 * @param {int|Function} configuration.activeAt - ScrollY position to trigger
 * the 'active' menu class. If a function, it is called with the current
 * navbar state and content props.
 * @param {integer} [configuration.setDuration] - Number in seconds for overriding
 * the local animations duration. If not provided, the local animations duration
 * is not changed.
 * @param {Function} [configuration.headline] - Function that determines content
 * headline.
 * @param {Function} [configuration.showShortcuts] - Function that determines
 * shortcuts value.
 * @param {Function} [configuration.theme] - Function that determines theme value.
 * @returns {Function} A higher-order component that wraps a Page component with
 * the Navbar component.
 */
export function navbar(configuration) {
  // navbar() configuration options
  const {
    // ScrollY position to trigger the 'active' menu class
    // @param {integer|function}
    activeAt: getActiveAt,

    // Number in seconds for overriding the local animations duration
    setDuration: localAnimationDuration,

    // Function that determines content headline
    headline: getHeadline,

    // Disable or enable the navigation shortcuts. This is enabled by default
    // Function that determines shortcuts value
    showShortcuts: getShowShortcuts,

    // Function that determines theme value
    theme: getTheme,
  } = configuration;

  return function navbarWrapper(Page) {
    class Navbar extends React.Component {
      static WrappedComponent = Page;

      static propTypes = {
        children: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.node),
          PropTypes.node,
        ]),
        navbarContent: PropTypes.shape({}).isRequired,
        navbarActive: PropTypes.bool,
        navbarActivate: PropTypes.func,
        navbarDeactivate: PropTypes.func,
        navbarSetHeadline: PropTypes.func,
        navbarSetShortcutVisible: PropTypes.func,
        navbarSetTheme: PropTypes.func,
        navbarSetDuration: PropTypes.func,
        path: PropTypes.string,
        pageView: PropTypes.string,
        section: PropTypes.string,
        vertical: PropTypes.string,
      };

      static defaultProps = {
        children: null,
        navbarActive: false,
        navbarActivate: Function.prototype,
        navbarDeactivate: Function.prototype,
        navbarSetHeadline: Function.prototype,
        navbarSetShortcutVisible: Function.prototype,
        navbarSetTheme: Function.prototype,
        navbarSetDuration: null,
        path: null,
        pageView: null,
        section: null,
        vertical: null,
      };

      /**
       * Class constructor.
       *
       * @param {object} props - The props passed to the component.
       *
       * Sets the headline, shortcuts, theme, and duration based on the props.
       */
      constructor(props) {
        super(props);

        this.setHeadline();
        this.setShowShortcuts();
        this.setTheme();
        this.setDuration();
      }

      /**
       * Monitor the scroll position of the page to determine when to activate the navbar.
       *
       * If the vertical is 'today', do not monitor the scroll position.
       *
       * If a getActiveAt function is provided, call the function with the vertical and navbarContent
       * props and use the result as the activeAt value. If the result is not null, monitor the scroll
       * position and activate the navbar when the page is scrolled to the activeAt position.
       *
       * @see getActiveAt
       */
      componentDidMount() {
        const { vertical, navbarContent } = this.props;
        if (getActiveAt) {
          this.activeAt = (typeof getActiveAt === 'function')
            ? getActiveAt({ vertical, navbarContent })
            : getActiveAt;

          if (this.activeAt) {
            BTE.on('scroll', this.monitorScroll, 'throttle');
          }
        }
      }


      /**
       * Determines if the component should re-render.
       * Returns false to prevent any updates after the initial render.
       * This can be used for optimization when the component does not need to update.
       * @returns {boolean} - Always returns false.
       */
      shouldComponentUpdate() {
        return false;
      }

      /**
       * Lifecycle method called just before the component is unmounted and destroyed.
       * It removes the scroll event listener if `activeAt` is defined to prevent memory leaks.
       */
      componentWillUnmount() {
        if (this.activeAt) {
          BTE.remove('scroll', this.monitorScroll, 'throttle');
        }
      }

      /**
       * Event handler for the 'scroll' event, throttled to 30 milliseconds.
       * Checks if the current scroll depth is greater than the `activeAt` value.
       * If it is, and the navbar is not currently active, it activates the navbar.
       * If it is not, and the navbar is currently active, it deactivates the navbar.
       *
       * @param {number} depth - the current scroll depth
       */
      monitorScroll = (depth) => {
        const {
          navbarActive,
          navbarActivate,
          navbarDeactivate,
        } = this.props;

        if (depth > this.activeAt && !navbarActive) {
          navbarActivate();
        } else if (depth < this.activeAt && navbarActive) {
          navbarDeactivate();
        }
      }

      /**
      * A function that is called when the navbar is mounted. It sets the navbar's headline
      * based on the getHeadline function, if it exists. The getHeadline function is
      * expected to take an object with the content property, and return the headline
      * to be displayed.
      */
      setHeadline = () => {
        if (getHeadline) {
          const {
            navbarContent,
            navbarSetHeadline,
          } = this.props;

          const headline = getHeadline({
            content: navbarContent,
          });
          navbarSetHeadline(headline);
        }
      }

      /**
        * A function that is called when the navbar is mounted. It sets the navbar's
        * shortcuts visibility based on the getShowShortcuts function, if it exists.
        * The getShowShortcuts function is expected to take an object with the
        * content property, and return a boolean indicating whether the shortcuts
        * should be visible.
      */
      setShowShortcuts = () => {
        if (getShowShortcuts) {
          const {
            navbarContent,
            navbarSetShortcutVisible,
          } = this.props;

          const shortcuts = getShowShortcuts({
            content: navbarContent,
          });

          navbarSetShortcutVisible(shortcuts);
        }
      }

      /**
       * If localAnimationDuration is greater than -1, sets the duration of navbar
       * animations using the navbarSetDuration action creator.
       */
      setDuration = () => {
        if (localAnimationDuration > -1) {
          const { navbarSetDuration } = this.props;
          navbarSetDuration(localAnimationDuration);
        }
      }

      /**
       * sets the theme
       */
      setTheme = () => {
        if (getTheme) {
          const {
            navbarContent,
            navbarSetTheme,
            path,
            section,
            vertical,
          } = this.props;

          const theme = getTheme({
            content: navbarContent,
            path,
            section,
            vertical,
          });
          navbarSetTheme(theme);
        }
      }

      /**
       * Returns a wrapped Page component with all navbar-related props stripped
       * from the props object.
       *
       * @returns {ReactElement} a wrapped Page component
       */
      render() {
        const {
          children, ...props
        } = this.props;

        const childProps = Object.keys(this.props)
          .filter((key) => key.search(/^navbar[A-Z]/) === -1)
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = props?.[key];
            return obj;
          }, {});

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Page data-test="nav-bar-page" {...childProps}>
            {children}
          </Page>
        );
      }
    }
    /**
     * Connects store props to the Navbar component.
     * @param {object} storeProps Redux store state.
     * @param {object} ownProps Navbar props.
     * @property {boolean} navbarActive Whether the navbar is active.
     * @property {object} navbarContent The content of the navbar.
     * @property {string} pageView The current page view.
     * @property {string} path The current path.
     * @property {string} section The current section.
     * @property {string} vertical The current vertical.
     * @returns {object} Props to pass to the Navbar component.
     */
    const mapStateToProps = (storeProps, ownProps) => {
      const {
        navbar: { active },
        article,
        front,
        recipe,
        slideshow,
        video,
      } = storeProps;

      const {
        pageView,
        path,
        section,
        vertical,
      } = ownProps;

      let navbarContent = {};
      switch (pageView) {
        case 'article':
        case 'showBlog':
          navbarContent = article?.content?.[0] ?? {};
          break;
        case 'front':
          navbarContent = front;
          break;
        case 'recipe':
          navbarContent = recipe?.current ?? {};
          break;
        case 'slideshow':
          navbarContent = slideshow?.current ?? {};
          break;
        case 'video':
          navbarContent = video;
          break;
        default:
          navbarContent = {};
      }

      return {
        // store props
        navbarActive: active,
        navbarContent,
        // own props
        pageView,
        path,
        section,
        vertical,
      };
    };

    /**
     * Maps dispatch to props for the Navbar component, allowing the component
     * to trigger various actions related to the navbar state. Each function
     * wraps an action creator and dispatches it, enabling the component
     * to activate or deactivate the navbar, set the visibility of shortcuts,
     * update the headline, set the theme, and adjust the duration of animations.
     *
     * @param {Function} dispatch - Redux dispatch function to send actions to the store.
     * @returns {object} An object containing functions that dispatch actions to update
     * the navbar state.
     */
    const mapActionsToProps = (dispatch) => ({
      /**
       * activates navbar
       */
      navbarActivate: () => dispatch(activateAction()),
      /**
       * Deactivate the navbar.
       */
      navbarDeactivate: () => dispatch(deactivateAction()),
      /**
       * Dispatches an action to set the visibility of shortcuts in the navbar state.
       * @param {boolean} payload - the new visibility value
       */
      navbarSetShortcutVisible: (payload) => dispatch(setShortcutsVisibleAction(payload)),
      /**
       * Dispatches an action to set the headline value in the navbar state.
       * @param {string} payload - the new headline value
       */
      navbarSetHeadline: (payload) => dispatch(setHeadlineAction(payload)),
      /**
       * Dispatches an action to set the theme value in the navbar state.
       * @param {string} payload - the new headline value
       */
      navbarSetTheme: (payload) => dispatch(setThemeAction(payload)),
      /**
       * Dispatches an action to set the duration of navbar visibility
       * @param {string} payload
       */
      navbarSetDuration: (payload) => dispatch(setDurationAction(payload)),
    });

    return connect(mapStateToProps, mapActionsToProps)(
      Navbar,
    );
  };
}
